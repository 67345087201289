/* COMMON */
.img50 {
  width: 50px;
  height: 50px;
}
.img120 {
  width: 120px;
  height: 120px;
}

.imageText {
  margin-top: 5px;
}
.pointer {
  cursor: pointer;
}
.textSelect-none {
  user-select: none;
}

/* FLEX */
.row {
  display: flex;
  flex-direction: row;
}

span,
h6 {
  color: #555;
}
.centerimageandtext {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.grid {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fill, 130px);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.premium_star {
  margin-left: 30px;
  margin-right: 10px;
  margin-top: -5px;
}

/* FOR ALL INPUTS */
/* input.form-control,
textarea.form-control {
} */

.check-status {
  font-size: 16px;
}

.check-status-completed {
  color: green;
}
