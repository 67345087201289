.filter-button {
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif;
  border: 1px solid transparent;
  border-color: #007bff;
  background-color: transparent;
  background-image: none;
  color: #007bff;
  padding: 0.5625rem 1rem;
  font-size: 0.75rem;
  line-height: 1.125;
  border-radius: 0.25rem;
  /* transition: all 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06); */
  box-shadow: none;
}
.filter-button:focus,
.filter-button:active {
  outline: none !important;
  box-shadow: none;
}

.filter-button-active {
  box-shadow: none;
  border: 1px solid transparent;
  border-color: #007bff;
  background-color: #007bff;
  color: #fff;
}
